/* .datesContainer {
  padding: 16px 0;
  display: flex;
  justify-content: space-around;
}

.mainDateBox {
  padding: 0 !important;
} */

.dateBox {
  border-radius: 3px;
  border: 1px solid #c1c9d2;
  height: 45px;
  width: 75px;
  text-align: center;
  padding: 2px 5px;
}

.weekday {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  color: #4f566b;
}

.monthDate {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #1a1f36;
}

/* hr {
  line-height: 1px;
  color: #e3e8ee;
} */

/* .timeEntry {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #2a2f45;
  height: 30px;
  width: 65px;
  border-radius: 3px;
  background-color: #f2f2f2;
  margin-bottom: 15px;
}

.timeEntry:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff !important;
  height: 30px;
  width: 65px;
  border-radius: 3px;
  background-color: #139696 !important;
  margin-bottom: 15px;
}

.selectTime {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff !important;
  height: 30px;
  width: 65px;
  border-radius: 3px;
  background-color: #139696 !important;
  margin-bottom: 15px;
} */

/* .arrowButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 !important;
}

.arrowButtonRt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -22px !important;
} */

.expandBtn {
  display: flex;
  justify-content: center;
  color: #3c4257;
}

/* .hideBtn {
  display: none;
}

.line {
  color: #e3e8ee !important;
  size: 0.5px !important;
} */

.noDates {
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;
}

.noDatesText {
  color: #4f566b;
  font-weight: 600;
}

/* Updated classes ********************* */
.dateSlider {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.dateSlider__arrowBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.2rem;
}

.disabled__arrowBtn {
  pointer-events: none;
  color: #828282;
  opacity: 0.5;
}

/* @media (max-width: 325px) {
  .dateSlider__arrowBtn {
    width: 40px;
  }
} */

.dateSlider__dateItems {
  display: flex;
  gap: 0.5rem;
}

.timeSlots {
}

.timeSlots--wrapper {
}

.timeSlotsDefault {
  display: flex;
  justify-content: center;
  padding: 1rem 3rem;
  max-height: 98px;
  overflow: hidden;
  gap: 0.5rem;
}

.timeSlotsExpanded {
  display: flex;
  justify-content: center;
  padding: 1rem 3rem;
  gap: 0.8rem;
}

.timeslot {
  min-width: 75px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timeSlot--default {
  background: #f2f2f2;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.timeSlot--default:hover {
  background: #f2f2f2;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.timeSlot--active {
  background: #139696 !important;
  color: #ffffff !important;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
